import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Code from '../components/code';
import Caption from '../components/Caption';
import LittleImg from '../components/LittleImg';
import Video from '../components/Video';
import Action from '../components/Action';
export const _frontmatter = {
  "title": "Flexbox Zombies 2.0",
  "date": "2020-11-06",
  "promo": "flexbox",
  "pic": "/fbz/fbz-banner.jpg",
  "description": "Play the newly polished flexbox mastery game",
  "color": "#59c7cf"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Flexbox is still a very important tool for all web devs to learn. CSS Grid is the new layout hotness — and it's amazing — but Flexbox still has its place in a lot of layout situations. Problem is, Flexbox is still hard to learn. Browser devtools like Firefox's `}<a parentName="p" {...{
        "href": "/post/flexbox-inspector/"
      }}>{`Flexbox Inspector`}</a>{` are helping. But nothing compares to `}<a parentName="p" {...{
        "href": "/post/power-of-mastery/"
      }}>{`mastering tech yourself`}</a>{`, so that you `}<em parentName="p">{`don't`}</em>{` need a cheatsheet, you `}<em parentName="p">{`don't`}</em>{` have to struggle and you `}<em parentName="p">{`don't`}</em>{` have to constantly look things up. My goal with Mastery Games® is to make mastering things like Flexbox and Grid as easy and enjoyable as possible.`}</p>
    <p><a parentName="p" {...{
        "href": "/flexboxzombies"
      }}>{`Flexbox Zombies`}</a>{` was the first game I created as I set out on `}<a parentName="p" {...{
        "href": "/post/overcoming-fear-and-doing-your-own-thing/"
      }}>{`my entrepreneur journey`}</a>{`. Since releasing it over 60,000 people have used it (for free!) to finally learn Flexbox. I've been proud of my little creation, but for a long time I've wanted to bring this game closer to the quality of my paid CSS Grid game `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{`. `}</p>
    <p>{`That time has finally come—`}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` 2.0 is live! I've decided to keep the game free (for now at least). Here are some highlights of this big update!`}</p>
    <h2>{`Animations`}</h2>
    <p>{`In this game you control a Hoodie character with a crossbow, using Flexbox code. The goal is to learn Flexbox in order to survive the onslaught of zombies. These characters used to just be static images. But now they've all come to life with fun `}<a parentName="p" {...{
        "href": "/post/character-animation/"
      }}>{`Spine`}</a>{` animations.`}</p>
    <Video src="/video/zombie-animated.mp4" maxWidth="220px" mdxType="Video" />
    <h2>{`Immersive Audio`}</h2>
    <p>{`Some of my favorite feedback from folks goes something like this: `}</p>
    <blockquote>
      <p parentName="blockquote">{`It felt like I was playing a fun indie game, I forgot I was learning. But it totally worked and now I've got Flexbox down!`}</p>
    </blockquote>
    <p>{`I wanted to make the experience even more immersive. So Flexbox Zombies now has its own original soundtrack composition, atmospheric background and awesome sound effects. I'm especially excited for you to hear the delightful sound the 'goo' zombies make when you shoot 'em :)`}</p>
    <h2>{`Improved Zombie Targeting`}</h2>
    <p>{`I did something in `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` that students tell me they appreciate: when you get the CSS Grid code right for an item, it lights up to let you know that you got that part of the challenge right.`}</p>
    <p>{`I've added a similar behavior to Flexbox Zombies. The targets and the zombies change when your Flexbox code locks 'em in correctly! `}</p>
    <p><img parentName="p" {...{
        "src": "/img/improved-targeting.jpg",
        "alt": "improved targeting"
      }}></img></p>
    <h2>{`Sweet Sweet Polish`}</h2>
    <p>{`I'm a big believer that the key to great products is `}<a parentName="p" {...{
        "href": "/post/polish-makes-perfect/"
      }}>{`constant polish`}</a>{`. So along with this update I've fixed a bunch of bugs, improved the hint system, updated level dialogues, fixed blasted typos and more.`}</p>
    <h2>{`Go Play`}</h2>
    <p>{`Mastering CSS Flexbox has never been so easy and fun. Get on it!`}</p>
    <Action link="https://mastery.games/flexboxzombies" mdxType="Action">Play Flexbox Zombies 2.0</Action>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      